import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import Image, { ImageType } from "../ImageComponent";
import Introduction from "../Introduction";
import SocialMedia from "../SocialMedia";
import { caption } from "../Typography";
import VerticalMargin from "../VerticalMargin";

const Wrapper = styled.div`
  border-top: 1px solid ${theme.color("dark.base")};
  border-bottom: 1px solid ${theme.color("dark.base")};
  padding: 1.25rem 0;

  ${mq("2")} {
    border: 0;
    display: flex;
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  flex: 1 1 100%;
  ${mq("2")} {
    flex: 1 1 60%;
    padding-right: ${theme.space("xl")};
  }

  ${mq("3")} {
    padding-right: 150px;
  }

  > * {
    margin-bottom: ${theme.space("s")};
  }
`;

const PersonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${mq("2")} {
    flex: 1 1 40%;
  }
`;

const PersonImageWrapper = styled.div`
  width: 92px;
  height: 92px;
  margin-right: ${theme.space("s")};
  overflow: hidden;

  ${mq("2")} {
    margin-bottom: ${theme.space("m")};
  }
`;

const PersonImage = styled(Image)`
  height: 100%;
  img {
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
`;

const PersonHeading = styled(Introduction)`
  margin-top: 0;
`;

const PersonName = styled.div`
  ${caption};
  font-weight: bold;
`;

const PersonTitle = styled.div`
  ${caption};
`;

const PersonSocial = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: ${theme.space("xs")};
`;

const StyledIcon = styled(SocialMedia)`
  margin-right: 10px;
`;
function ContactHighlightComponent({ title, text, person, cta, ...props }) {
  return (
    <VerticalMargin>
      <Wrapper {...props}>
        <TextWrapper>
          {title}
          {text}
        </TextWrapper>

        <PersonWrapper>
          <PersonImageWrapper>
            <PersonImage {...person.image} />
          </PersonImageWrapper>

          <div>
            <PersonHeading>
              {cta ? cta : `Bel of mail met ${person.name}.`}
            </PersonHeading>
            <PersonName>{person.name}</PersonName>
            <PersonTitle>{person.title}</PersonTitle>
            <PersonSocial>
              <StyledIcon
                type="mailOutline"
                link={`mailto:${person.email}`}
                inverted
              />
              <SocialMedia
                type="phoneOutline"
                link={`tel:${person.phone}`}
                inverted
              />
            </PersonSocial>
          </div>
        </PersonWrapper>
      </Wrapper>
    </VerticalMargin>
  );
}

ContactHighlightComponent.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
  person: PropTypes.shape({
    image: PropTypes.shape(ImageType),
    name: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  cta: PropTypes.string,
};

export default ContactHighlightComponent;
