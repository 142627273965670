import styled from "@emotion/styled";
import React from "react";

import theme from "../../theme";
import { contactHighlight } from "../../types";
import ContactHighlightComponent from "../ContactHighlightComponent";
import ContentWrapper from "../ContentWrapper";
import Heading from "../Heading";
import Text from "../Text";
import VerticalMargin from "../VerticalMargin";

const personMap = ({ firstname, avatar, title, phone, email }) => ({
  image: avatar,
  name: firstname,
  title,
  email,
  phone,
});

const StyledHeading = styled(Heading)`
  color: black;
  margin-bottom: ${theme.space("s")};
`;

const ContactHighlight = ({ title, description, person }) => (
  <VerticalMargin>
    <ContentWrapper columns={12}>
      <ContactHighlightComponent
        title={<StyledHeading size={2}>{title}</StyledHeading>}
        text={<Text html>{description}</Text>}
        person={personMap(person)}
      />
    </ContentWrapper>
  </VerticalMargin>
);

ContactHighlight.propTypes = contactHighlight;

export default ContactHighlight;
